.RewardForm {
    width: 80%;
    margin: auto;
}

.NewRewardTitleArea{
    width: 100%;
    height: 120px;
}

.NewRewardCompArea{
    width: 100%;
    height: 135px;
}

.NewRewardTierArea{
    width: 100%;
    height: 110px;
}

.NewRewardDescArea{
    width: 100%;
    height: 160px;
}

.NewRewardSubmitArea {
    width: 100%;
    height: 150px;
}

.NewRewardColumnContainer {
    clear: both;
    height: 50px;
}

.NewRewardColumn {
    float: left;
    width: 50%;
    height: 100%;
}

.NewRewardCounter {
    width: 100px;
    margin-right: 2em;
}

.NewRewardDescInput {
    width: 100%;
    height: 100px;
}

.NewRewardTitleInput {
    width: 100%;
    font-size: 24px;
}

.NewRewardSubButton {
    width: 300px;
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.NewRewardSubButton:hover {
    color: white;
}

.NewRewardCkBx {
    height: 20px;
    width: 20px;
}

.NewRewardQuantLabel {
    margin-right: 10px;
}