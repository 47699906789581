.FooterContainer {
    width: 100%;
    height: 40px;
    background: #D1D1F6;
    font-size: 28px;
}

.FooterContent {
    width: 100%;
}

.FooterLine {
    background: #63326E;
    width: 100%;
    height: 3px;
}

.LeftFooter {
    width: 55%;
    display: inline-block;
    font-size: 18px;
}

.RightFooter {
    width: 45%;
    display: inline-block;
    text-align: right;
}

.RFooterPadder {
    padding-right: 15px;
}

.FItemSpacer {
    display: inline-block;
    margin-left: 30px;
}

.FooterClickLabel {
    border-bottom: 2px solid #D1D1F6;
    text-decoration: none;
    color: #63326E;
}

.FooterClickLabel:hover {
    border-color: black;
}