.MyTokensArea {
    height: 650px;
}

.RewardTokensArea {
    height: 700px;
}

.TokenReclaimButton {
    width: 350px;
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.ReclaimError {
    display: inline-block;
}