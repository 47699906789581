.NavContainer {
    clear: both;
    height: 100%;
    width: 100%;
}

.NavButton {
    width: 100%;
    border: 0px;
    background-color: #63326E;
    font-size: 24px;
    height: 100%;
    color: white;
}

.UserNavigation {
    width: 70%;
    float: left;
    height: 100%;
}

.NavDivider {
    width: 3px;
    background-color: #976391;
    height: 80%;
    float: left;
    margin-top: 8px;
}

.NavItem {
    float: left;
    height: 100%;
    width: 175px;
}

.NavSubMenuArea {
    background-color: #63326E;
    border-top: 0px;
    width: 225px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 1px 2px 1px black;
}

.NavSubMenuPadder {
    padding: 10px;
}

.NavSubMenuItem {
    border: 2px solid #63326E;
    color: white;
    font-size: 20px;
    text-decoration: none;
}

.NavSubMenuItem:hover {
    border-bottom: 2px solid #D1D1F6;
}