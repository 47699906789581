.GoalForm {
    width: 80%;
    margin: auto;
}

.NewGoalTitleArea{
    width: 100%;
    height: 120px;
}

.NewGoalCompArea{
    width: 100%;
    height: 135px;
}

.NewGoalTierArea{
    width: 100%;
    height: 110px;
}

.NewGoalDescArea{
    width: 100%;
    height: 160px;
}

.NewGoalSubmitArea {
    width: 100%;
    height: 150px;
}

.NewGoalColumnContainer {
    clear: both;
    height: 50px;
}

.NewGoalColumn {
    float: left;
    width: 50%;
    height: 100%;
}

.NewGoalCounter {
    width: 100px;
    margin-right: 2em;
}

.NewGoalDescInput {
    width: 100%;
    height: 100px;
}

.NewGoalTitleInput {
    width: 100%;
    font-size: 24px;
}

.NewGoalSubButton {
    width: 250px;
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.NewGoalSubButton:hover {
    color: white;
}

.NewGoalCkBx {
    height: 20px;
    width: 20px;
}

.NewGoalQuantLabel {
    margin-right: 10px;
}