.Loginbar {
    width: 100%;    
}

.LoginFormPadder {
    float: right;
    padding-right: 20px;
    padding-top: 20px;
    font-size: 20px;
}

.LoginBarLabel {
    padding-left: 5px;
    padding-right: 5px;
    color: #D1D1F6;
}

.LoginBarInput {
    border-radius: 5px;
    border: 2px solid #D1D1F6;
    font-size: 20px;
    width: 150px;
    background: none;
    color: white;
    padding-left: 5px;
    caret-color: white;
    box-shadow: 1px 1px black;
}

.LoginBarSubmit {
    border-radius: 5px;
    border: 0px;
    background: #D1D1F6;
    font-size: 22px;
    margin-left: 10px;
    box-shadow: 1px 2px 2px;
    font-weight: bold;
}

.LoginBarSubmit:hover {
    color: #a11aef;
}