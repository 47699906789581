.AccountPanel {
    height: 760px;
}

.SubscribeButton {
    width: 220px;
    height: 40px;
    display: inline-block;
    margin-bottom: .5em;
    margin-top: 0;
}