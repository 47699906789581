body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ColorHolder {
    background-color: #D1D1F6;
    background-color: #c584ff;
    background-color: #a11aef;
    background-color: #976391;
    background-color: #63326E;
    background-color: #F7996E;
    background-color: #D6FFB7;
    background-color: #FFF689;
}

.ErrorMessage {
    color: red;
    font-size: 32px;
    height: 50px;
}

.SuccessMessage {
    color: green;
    font-size: 32px;
    height: 50px;
}

.CompleterErrorMessage {
    height: 85px;
}

.ListDivider {
    height: 2px;
    background-color: #D1D1F6;
    width: 60%;
    margin: auto;
}

.Pointer {
    cursor: pointer;
}

.App {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
}

input {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
}

select {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
}

textarea {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
}

.TopContentArea {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #63326E;
    box-shadow: 1px 3px 3px;
}

.TopSpacer {
    width: 100%;
    height: 70px;
}

.AppTitleContainer {
    float: left;
    width: 20%;
    height: 100%;
}

.TitleBox {
    width: 80%;
    border-radius: 10px;
    border: 2px solid #D1D1F6;
    background: #D1D1F6;
    margin-left: 10px;
    margin-top: 10px;
    padding-left: 10px;
    padding-top: 5px;
    height: 40px;
    box-shadow: 1px 3px 3px;
}

.AppTitle {
    font-size: 28px;
    font-weight: bold;
    color: #63326E;
}

.BodyContainer {
    clear: both;
    text-align: center;
    width: 100%;
}

.ContentArea {
    width: 100%;
    overflow: hidden;
}

.ContentPadder {
    width: 80%;
    margin: auto;
    overflow: hidden;
    padding-top: 120px;
    min-height: 700px;
}

.White {
    background: white;
}

.Purple {
    background: #c584ff;
}

.SlimHeader {
    margin-top: 0;
}

.LeftRightBig {
    float: left;
    width: 70%;
}

.LeftRightSmall {
    float: left;
    width: 30%;
    text-align: center;
}

.PurpleBorder {
    border: 5px solid #63326E;
    border-radius: 5px;
}

.Bold {
    font-weight: bold;
}

.FormLabel {
    font-size: 32px;
}

.TogglePageArea {
    height: 50px;
    padding-top: 50px;
}

.TogglePageLabel {
    text-decoration: underline;
    font-size: 18px;
}

.UserFormSubmitButton {
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.OptionsConfirmRejectButton {
    font-size: 32px;
    font-weight: bold;
    color: #63326E;
    border-radius: 5px;
    border: 0px;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px black;
    width: 200px;
}

.OptionsConfirmRejectButton:hover {
    color: #a11aef;
}

.OptionsPadder {
    display: inline-block;
    padding-right: 40px;
}

.DetailsSingleLine {
    height: 60px;
}

.DetailsLabel {
    height: 35px;
    color: #63326E;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
}

.DetailsTitle {
    height: 70px;
    font-size: 28px;
}

.DetailsDescription {
    min-height: 60px;
    max-height: 100px;
    font-size: 28px;
    width: 100%;
}

.DetailsSubmitButton {
    font-size: 32px;
    font-weight: bold;
    color: #63326E;
    border-radius: 5px;
    border: 0px;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px black;
    width: 200px;
}

.FullWidth {
    width: 100%;
}

.SizedMessageTextarea {
    width: 500px;
}
.BrowseListArea {
    height: 600px;
}
.HzontalHeader {
    width: 815px;
    margin-top: 0;
    padding-left: 40px;
}

.HzontalPanelArea {
    width: 90%;
    margin: auto;
    min-width: 815px;
}

.HzontalScrollArea {
    height: 290px;
    padding-top: 210px;
    float: left;
    width: 45px;
}

.HzontalScrollButton {
    color: #63326E;
    font-size: 48px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    background: #D1D1F6;
}

.HzontalScrollButton:hover {
    color: white;
}

.HorizontalList {
    overflow: hidden;
    touch-action: pan-x;
    height: 500px;
    width: 720px;
    margin: auto;
    border: 5px solid #63326E;
    border-radius: 15px;
    white-space: nowrap;
    background: white;
    float: left;
}

.HorizontalListItem {
    display: inline-block;
    width: 720px;
    font-size: 32px;
    height: 100%;
}

.HzontalLabelArea {
    height: 35px;
    color: #63326E;
    width: 100%;
    font-weight: bold;
    font-size: 28px;
}

.HzontalTitleArea {
    height: 70px;
    font-size: 32px;
}

.HzontalMessageArea {
    min-height: 60px;
    max-height: 100px;
    font-size: 32px;
}

.HzontalUserArea {
    height: 60px;
}

.HzontalTierArea {
    height: 60px;
}

.HzontalQuantityArea {
    height: 60px;
}

.HzontalTopItem {
    padding-top: 50px;
}
.BrowseByArea {
    height: 130px;
}

.BrowseForArea{
    height: 100px;
}

.SearchValue {
    margin-left: 1em;
}

.BrowseFilterBySelect {
    width: 200px;
}

.BrowseFilterSubButton {
    width: 200px;
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.BrowseFilterSubButton:hover {
    color: white;
}
.CompReqArea {
    height: 650px;
}

.MyGoalsArea {
    height: 650px;
}
.GoalForm {
    width: 80%;
    margin: auto;
}

.NewGoalTitleArea{
    width: 100%;
    height: 120px;
}

.NewGoalCompArea{
    width: 100%;
    height: 135px;
}

.NewGoalTierArea{
    width: 100%;
    height: 110px;
}

.NewGoalDescArea{
    width: 100%;
    height: 160px;
}

.NewGoalSubmitArea {
    width: 100%;
    height: 150px;
}

.NewGoalColumnContainer {
    clear: both;
    height: 50px;
}

.NewGoalColumn {
    float: left;
    width: 50%;
    height: 100%;
}

.NewGoalCounter {
    width: 100px;
    margin-right: 2em;
}

.NewGoalDescInput {
    width: 100%;
    height: 100px;
}

.NewGoalTitleInput {
    width: 100%;
    font-size: 24px;
}

.NewGoalSubButton {
    width: 250px;
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.NewGoalSubButton:hover {
    color: white;
}

.NewGoalCkBx {
    height: 20px;
    width: 20px;
}

.NewGoalQuantLabel {
    margin-right: 10px;
}
.TierSelect {
    width: 200px;
}
.LoginFormButton {
    width: 150px;
}

.LoginFormButton:hover {
    color: white;
}
.CreateUserForm {
    width: 80%;
    margin: auto;
}

.CreateFormUserArea {
    height: 120px;
}

.CreateFormPwArea {
    height: 120px;
}

.CreateFormButton {
    width: 350px;
}

.CreateFormButton:hover {
    color: white;
}
.RewardRedemArea {
    height: 700px;
}

.RewardClaimedArea {
    height: 650px;
}

.MyRedemRequestsArea {
    height: 650px;
}
.RewardForm {
    width: 80%;
    margin: auto;
}

.NewRewardTitleArea{
    width: 100%;
    height: 120px;
}

.NewRewardCompArea{
    width: 100%;
    height: 135px;
}

.NewRewardTierArea{
    width: 100%;
    height: 110px;
}

.NewRewardDescArea{
    width: 100%;
    height: 160px;
}

.NewRewardSubmitArea {
    width: 100%;
    height: 150px;
}

.NewRewardColumnContainer {
    clear: both;
    height: 50px;
}

.NewRewardColumn {
    float: left;
    width: 50%;
    height: 100%;
}

.NewRewardCounter {
    width: 100px;
    margin-right: 2em;
}

.NewRewardDescInput {
    width: 100%;
    height: 100px;
}

.NewRewardTitleInput {
    width: 100%;
    font-size: 24px;
}

.NewRewardSubButton {
    width: 300px;
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.NewRewardSubButton:hover {
    color: white;
}

.NewRewardCkBx {
    height: 20px;
    width: 20px;
}

.NewRewardQuantLabel {
    margin-right: 10px;
}
.MyTokensArea {
    height: 650px;
}

.RewardTokensArea {
    height: 700px;
}

.TokenReclaimButton {
    width: 350px;
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.ReclaimError {
    display: inline-block;
}
.SplashGraphics {
    height: 100%;
    width: 30%;
    float: left;
}

.SplashBody {
    float: left;
    text-align: left;
    width: 70%;
    height: 650px;
}

.FakePicture {
    height: 250px;
    width: 200px;
    background: #D1D1F6;
    border: 3px solid #63326E;
    display: inline-block;
    margin-top: 100px;
    box-shadow: 1px 1px;
}

.HowTitle {
    width: 350px;
}

.HowBubble {
    background: #D1D1F6;
    border: 3px solid #63326E;
    border-radius: 10px;
    box-shadow: 1px 2px 2px;
    margin-bottom: 600px;
}

.LeftRight {
    float: left;
    width: 49%;
    text-align: justify;
    height: 700px;
}

.Centered {
    text-align: center;
}

.VerticalDivider {
    height: 700px;
    width: 5px;
    background: #63326E;
    float: left;
}

.LeftRightPadder {
    padding-left: 20px;
    padding-right: 20px;
}

.StartButtonArea {
    height: 200px;
    clear: left;
    width: 100%;
}

.StartButtonPadder {
    width: 100%;
    height: 100%;
}

.GetStartedButton {
    width: 275px;
    height: 75px;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    font-size: 36px;
    font-weight: bold;
    margin-top: 10px;
    box-shadow: 1px 2px 2px;
    padding-top: 10px;
    padding-bottom: 40px;
}

.GetStartedButton:hover {
    color: white;
}

.StartProvoke {
    font-size: 28px;
    width: 100%;
}
.ChangelogList {
    width: 500px;
    margin: auto;
}

.HeaderSubtext {
    font-size: .8em;
    font-weight: normal;
}
.AccountPanel {
    height: 760px;
}

.SubscribeButton {
    width: 220px;
    height: 40px;
    display: inline-block;
    margin-bottom: .5em;
    margin-top: 0;
}
.ChangePasswordArea {
    height: 165px;
}

.ChangePasswordButton {
    width: 180px;
    margin-top: 0px;
}

.ChangePasswordButton:hover {
    color: white;
}
.Userbar {
    width: 80%;
    float: left;
    height: 100%;
}
.Userbadge {
    width: 30%;
    height: 100%;
    color: white;
    float: left;
}

.BadgePadder {
    padding-right: 30px;
    padding-top: 25px;
    float: right;
}

.UserOptionsToggle {
    border-radius: 5px;
    border: 0px;
    margin-left: 10px;
    padding: 0 5px 2px 5px;
    background: #D1D1F6;
    color: #63326E;
    font-weight: bold;
    box-shadow: 1px 2px 1px black;
}

.UserOptionsToggle:hover {
    color: white;
}

.UserDropdown {
    width: 14%;
    position: fixed;
    background: #63326E;
    border: 2px solid #D1D1F6;
    border-radius: 10px;
    top: 5%;
    left: 85%;
    box-shadow: 1px 2px 1px black;
}

.UserDropPadder {
    padding: 10px;
    color: white;
    line-height: 1.4em;
}

.UserDropItem {
    width: 100%;
    border: 3px solid #63326E;
    text-decoration: none;
    color: white;
}

.UserDropItem:hover {
    border-bottom: 2px solid #D1D1F6;
}

.NavContainer {
    clear: both;
    height: 100%;
    width: 100%;
}

.NavButton {
    width: 100%;
    border: 0px;
    background-color: #63326E;
    font-size: 24px;
    height: 100%;
    color: white;
}

.UserNavigation {
    width: 70%;
    float: left;
    height: 100%;
}

.NavDivider {
    width: 3px;
    background-color: #976391;
    height: 80%;
    float: left;
    margin-top: 8px;
}

.NavItem {
    float: left;
    height: 100%;
    width: 175px;
}

.NavSubMenuArea {
    background-color: #63326E;
    border-top: 0px;
    width: 225px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 1px 2px 1px black;
}

.NavSubMenuPadder {
    padding: 10px;
}

.NavSubMenuItem {
    border: 2px solid #63326E;
    color: white;
    font-size: 20px;
    text-decoration: none;
}

.NavSubMenuItem:hover {
    border-bottom: 2px solid #D1D1F6;
}
.Loginbar {
    width: 100%;    
}

.LoginFormPadder {
    float: right;
    padding-right: 20px;
    padding-top: 20px;
    font-size: 20px;
}

.LoginBarLabel {
    padding-left: 5px;
    padding-right: 5px;
    color: #D1D1F6;
}

.LoginBarInput {
    border-radius: 5px;
    border: 2px solid #D1D1F6;
    font-size: 20px;
    width: 150px;
    background: none;
    color: white;
    padding-left: 5px;
    caret-color: white;
    box-shadow: 1px 1px black;
}

.LoginBarSubmit {
    border-radius: 5px;
    border: 0px;
    background: #D1D1F6;
    font-size: 22px;
    margin-left: 10px;
    box-shadow: 1px 2px 2px;
    font-weight: bold;
}

.LoginBarSubmit:hover {
    color: #a11aef;
}
.FooterContainer {
    width: 100%;
    height: 40px;
    background: #D1D1F6;
    font-size: 28px;
}

.FooterContent {
    width: 100%;
}

.FooterLine {
    background: #63326E;
    width: 100%;
    height: 3px;
}

.LeftFooter {
    width: 55%;
    display: inline-block;
    font-size: 18px;
}

.RightFooter {
    width: 45%;
    display: inline-block;
    text-align: right;
}

.RFooterPadder {
    padding-right: 15px;
}

.FItemSpacer {
    display: inline-block;
    margin-left: 30px;
}

.FooterClickLabel {
    border-bottom: 2px solid #D1D1F6;
    text-decoration: none;
    color: #63326E;
}

.FooterClickLabel:hover {
    border-color: black;
}
