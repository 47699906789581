.BrowseByArea {
    height: 130px;
}

.BrowseForArea{
    height: 100px;
}

.SearchValue {
    margin-left: 1em;
}

.BrowseFilterBySelect {
    width: 200px;
}

.BrowseFilterSubButton {
    width: 200px;
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.BrowseFilterSubButton:hover {
    color: white;
}