.HzontalHeader {
    width: 815px;
    margin-top: 0;
    padding-left: 40px;
}

.HzontalPanelArea {
    width: 90%;
    margin: auto;
    min-width: 815px;
}

.HzontalScrollArea {
    height: 290px;
    padding-top: 210px;
    float: left;
    width: 45px;
}

.HzontalScrollButton {
    color: #63326E;
    font-size: 48px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    background: #D1D1F6;
}

.HzontalScrollButton:hover {
    color: white;
}

.HorizontalList {
    overflow: hidden;
    touch-action: pan-x;
    height: 500px;
    width: 720px;
    margin: auto;
    border: 5px solid #63326E;
    border-radius: 15px;
    white-space: nowrap;
    background: white;
    float: left;
}

.HorizontalListItem {
    display: inline-block;
    width: 720px;
    font-size: 32px;
    height: 100%;
}

.HzontalLabelArea {
    height: 35px;
    color: #63326E;
    width: 100%;
    font-weight: bold;
    font-size: 28px;
}

.HzontalTitleArea {
    height: 70px;
    font-size: 32px;
}

.HzontalMessageArea {
    min-height: 60px;
    max-height: 100px;
    font-size: 32px;
}

.HzontalUserArea {
    height: 60px;
}

.HzontalTierArea {
    height: 60px;
}

.HzontalQuantityArea {
    height: 60px;
}

.HzontalTopItem {
    padding-top: 50px;
}