.CreateUserForm {
    width: 80%;
    margin: auto;
}

.CreateFormUserArea {
    height: 120px;
}

.CreateFormPwArea {
    height: 120px;
}

.CreateFormButton {
    width: 350px;
}

.CreateFormButton:hover {
    color: white;
}