.ChangePasswordArea {
    height: 165px;
}

.ChangePasswordButton {
    width: 180px;
    margin-top: 0px;
}

.ChangePasswordButton:hover {
    color: white;
}