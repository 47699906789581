.Userbadge {
    width: 30%;
    height: 100%;
    color: white;
    float: left;
}

.BadgePadder {
    padding-right: 30px;
    padding-top: 25px;
    float: right;
}

.UserOptionsToggle {
    border-radius: 5px;
    border: 0px;
    margin-left: 10px;
    padding: 0 5px 2px 5px;
    background: #D1D1F6;
    color: #63326E;
    font-weight: bold;
    box-shadow: 1px 2px 1px black;
}

.UserOptionsToggle:hover {
    color: white;
}

.UserDropdown {
    width: 14%;
    position: fixed;
    background: #63326E;
    border: 2px solid #D1D1F6;
    border-radius: 10px;
    top: 5%;
    left: 85%;
    box-shadow: 1px 2px 1px black;
}

.UserDropPadder {
    padding: 10px;
    color: white;
    line-height: 1.4em;
}

.UserDropItem {
    width: 100%;
    border: 3px solid #63326E;
    text-decoration: none;
    color: white;
}

.UserDropItem:hover {
    border-bottom: 2px solid #D1D1F6;
}
