.ColorHolder {
    background-color: #D1D1F6;
    background-color: #c584ff;
    background-color: #a11aef;
    background-color: #976391;
    background-color: #63326E;
    background-color: #F7996E;
    background-color: #D6FFB7;
    background-color: #FFF689;
}

.ErrorMessage {
    color: red;
    font-size: 32px;
    height: 50px;
}

.SuccessMessage {
    color: green;
    font-size: 32px;
    height: 50px;
}

.CompleterErrorMessage {
    height: 85px;
}

.ListDivider {
    height: 2px;
    background-color: #D1D1F6;
    width: 60%;
    margin: auto;
}

.Pointer {
    cursor: pointer;
}

.App {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
}

input {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
}

select {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
}

textarea {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
}

.TopContentArea {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #63326E;
    box-shadow: 1px 3px 3px;
}

.TopSpacer {
    width: 100%;
    height: 70px;
}

.AppTitleContainer {
    float: left;
    width: 20%;
    height: 100%;
}

.TitleBox {
    width: 80%;
    border-radius: 10px;
    border: 2px solid #D1D1F6;
    background: #D1D1F6;
    margin-left: 10px;
    margin-top: 10px;
    padding-left: 10px;
    padding-top: 5px;
    height: 40px;
    box-shadow: 1px 3px 3px;
}

.AppTitle {
    font-size: 28px;
    font-weight: bold;
    color: #63326E;
}

.BodyContainer {
    clear: both;
    text-align: center;
    width: 100%;
}

.ContentArea {
    width: 100%;
    overflow: hidden;
}

.ContentPadder {
    width: 80%;
    margin: auto;
    overflow: hidden;
    padding-top: 120px;
    min-height: 700px;
}

.White {
    background: white;
}

.Purple {
    background: #c584ff;
}

.SlimHeader {
    margin-top: 0;
}

.LeftRightBig {
    float: left;
    width: 70%;
}

.LeftRightSmall {
    float: left;
    width: 30%;
    text-align: center;
}

.PurpleBorder {
    border: 5px solid #63326E;
    border-radius: 5px;
}

.Bold {
    font-weight: bold;
}

.FormLabel {
    font-size: 32px;
}

.TogglePageArea {
    height: 50px;
    padding-top: 50px;
}

.TogglePageLabel {
    text-decoration: underline;
    font-size: 18px;
}

.UserFormSubmitButton {
    font-size: 36px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px;
    margin-top: 25px;
    height: 65px;
}

.OptionsConfirmRejectButton {
    font-size: 32px;
    font-weight: bold;
    color: #63326E;
    border-radius: 5px;
    border: 0px;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px black;
    width: 200px;
}

.OptionsConfirmRejectButton:hover {
    color: #a11aef;
}

.OptionsPadder {
    display: inline-block;
    padding-right: 40px;
}

.DetailsSingleLine {
    height: 60px;
}

.DetailsLabel {
    height: 35px;
    color: #63326E;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
}

.DetailsTitle {
    height: 70px;
    font-size: 28px;
}

.DetailsDescription {
    min-height: 60px;
    max-height: 100px;
    font-size: 28px;
    width: 100%;
}

.DetailsSubmitButton {
    font-size: 32px;
    font-weight: bold;
    color: #63326E;
    border-radius: 5px;
    border: 0px;
    background: #D1D1F6;
    box-shadow: 1px 3px 3px black;
    width: 200px;
}

.FullWidth {
    width: 100%;
}

.SizedMessageTextarea {
    width: 500px;
}