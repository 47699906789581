.SplashGraphics {
    height: 100%;
    width: 30%;
    float: left;
}

.SplashBody {
    float: left;
    text-align: left;
    width: 70%;
    height: 650px;
}

.FakePicture {
    height: 250px;
    width: 200px;
    background: #D1D1F6;
    border: 3px solid #63326E;
    display: inline-block;
    margin-top: 100px;
    box-shadow: 1px 1px;
}

.HowTitle {
    width: 350px;
}

.HowBubble {
    background: #D1D1F6;
    border: 3px solid #63326E;
    border-radius: 10px;
    box-shadow: 1px 2px 2px;
    margin-bottom: 600px;
}

.LeftRight {
    float: left;
    width: 49%;
    text-align: justify;
    height: 700px;
}

.Centered {
    text-align: center;
}

.VerticalDivider {
    height: 700px;
    width: 5px;
    background: #63326E;
    float: left;
}

.LeftRightPadder {
    padding-left: 20px;
    padding-right: 20px;
}

.StartButtonArea {
    height: 200px;
    clear: left;
    width: 100%;
}

.StartButtonPadder {
    width: 100%;
    height: 100%;
}

.GetStartedButton {
    width: 275px;
    height: 75px;
    border-radius: 10px;
    border: 3px solid #63326E;
    background: #D1D1F6;
    font-size: 36px;
    font-weight: bold;
    margin-top: 10px;
    box-shadow: 1px 2px 2px;
    padding-top: 10px;
    padding-bottom: 40px;
}

.GetStartedButton:hover {
    color: white;
}

.StartProvoke {
    font-size: 28px;
    width: 100%;
}