.RewardRedemArea {
    height: 700px;
}

.RewardClaimedArea {
    height: 650px;
}

.MyRedemRequestsArea {
    height: 650px;
}